.SideBar{
    display: flex;
}

/*.right{*/
/*    width: 100%;*/
/*    margin-left: 25vw;*/
/*}*/

.left{
    background-color: white;
    top:0px;
    position: sticky;
    width: 25vw;
    height: 100vh;
    overflow: hidden;
    padding: 10px;
    padding-top: 130px;
    box-sizing: border-box;
}

.left ul{
    float: right;
}

.left h1{
    color: #292527;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 30px;
}

.left li i{
    color: #889cc4;
    font-size: 20px;
    margin-right: 20px;
}

.left li{
    color: #6B6E74;
    font-size: 16px;
    padding: 20px 40px 20px 10px;
    border-bottom: 1px solid #ededee;
}

.left li a{
    font-weight: bold;
}

.active{
    color: #292527;
    font-weight: bold;
}

.active i{
    color: #103989 !important;
}

.logout{
    color: #ea1a28;
    margin-top: 70px;
    font-weight: 700;
    padding-left: 10px;
    cursor: pointer;
}

.logout i{
    font-size: 20px;
    margin-right: 20px;
}

@media (max-width: 1200px){
    .left li{
        font-size: 14px;
    }
    .left h1{
        font-size: 20px;
        padding-left: 20px;
    }
    .left{
        padding: 0;
        padding-top: 100px;
    }
}

@media (max-width: 1100px){
    .left{
        width: 30vw;
    }
}

@media (max-width: 1000px){
    .left{
        width: 35vw;
    }
}

@media (max-width: 850px){
    .left{
        width: 40vw;
    }
    .left li{
        font-size: 13px;
    }
    .left h1{
        font-size: 18px;
        padding-left: 20px;
    }
}

@media (max-width: 700px){
    .left{
        display: none;
    }
}