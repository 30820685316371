.content{
    padding: 30px 10%;
}

.title{
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
}


.block{
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    margin-bottom: 30px;
}

.fileItem{
    display: flex;
    align-items: center;
}

.fileItem a{
    margin-left: 15px;
    color: #103989;
    text-decoration: underline;
    font-weight: 600;
}