
.wrapper{
    padding: 110px 10%;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-gap: 46px;
}


.linkItem{
    height:60px;
    background-color: white;
    border-radius: 4px;
    border-left: 5px solid white;
}

.linkItem:hover{
    border-left: 5px solid #103989;
}

.content{
    width: 95%;
}

.mainInfo{
    background-color:white;
    border-radius: 4px;
    padding:30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}

.imagesBox{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 6fr 3fr;
    margin-bottom: 50px;

}

.imagesBox > img{
    width: 100%;
    height: 400px;
    border-radius: 4px;
    object-fit: cover;
}

.otherPhotos{
    display:grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 3fr 3fr 3fr;
    grid-auto-rows: calc((400px - 30px) / 4);
}

.otherPhotos > img{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
}

.titleBox{
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 6fr 2fr 1fr;
    margin-bottom: 30px;
}

.blueButton{
    background-color: #103989;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    height: 40px;
    padding: 0 10px;
}

.titleBox p{
    align-self: center;
    color: #B4B6BA;
    cursor: pointer;
}

.titleBox h3{
    color: #103989;
    text-transform: uppercase;

}

.lotDetails{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.gridLine {
    grid-column: 1 / -1;
    height: 1px;
    background-image: url('../../assets/img/dashed-line.png');
}

.line{
    height: 1px;
    background-image: url('../../assets/img/dashed-line.png');
    margin-bottom: 30px;
}

.gridItem{
    padding: 30px 0;
}

.label{
    color: #292527;
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 4px;
}

.value{
    color: #292527;
    font-weight: 500;
    font-size: 15px;
}

.lotInfo, .lotOrganizer, .lotPayments, .lotDocuments, .lotPayment, .lotQuestions{
    background-color: white;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
}

.title{
    text-transform: uppercase;
    font-size: 23px;
    font-weight: 600;
}

.textItem{
    margin-bottom: 30px;
}

.textItemRow{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.smallTitle{
    text-transform: uppercase;
    margin-bottom: 20px;
}

.documentItem table td{
    color: #292527;
    padding-right: 10px;
    border-bottom: 1px dashed #ECEDED;
    padding-bottom: 30px;
    padding-top: 5px;
}

.documentItem table{
    width: 100%;
}

.tableDate, .tableName{
    text-align: left;
    font-weight: 400;
    color: #6B6E74;
    font-size: 14px;
    padding-right: 10px;
    padding-top: 10px;
}

.tableDate{
    width: 20%;
}

.tableName{
    width: 45%;
}

.document{
    display: flex;
    align-items: center;
    color: #103989;
    font-weight: 600;
    font-size: 16px;
    margin-top: 5px;
}

.document i{
    margin-right: 10px;
    font-size: 18px;
}
