  .navigation{
    background:white;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 900;
    padding: 0 10%;
    box-sizing: border-box;
  }
  .logo img{
    height: 60px;
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translate(-50%);
  }

  .checkbtn, .logo{
    z-index: 100;

  }
  .navigation .list{
    float: right;
    margin-right: 20px;
    display: flex;
  }

  .navigation .list li .link{
    color: black;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
  }
  
  .userBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 23px;
    color: #8f9297;
    position: relative;
  }

  .userIcon{
    max-width: 40px;
    margin: 0 10px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 50%;
  }

  .createLot{
    margin: 0 20px;
    padding: 5px 10px;
    height: 34px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
  }

  .fakeDiv{
    height: 80px;
  }

  .checkbtn{
    font-size: 25px;
    color: #4f4f4f;
    float: right;
    line-height: 80px;
    margin-right: 5%;
    cursor: pointer;
    display: none;
    margin-left: 10px;
  }
  #check{
    display: none;
  }

  .list{
    display: flex;
    align-items: center;
    height: 100%;
  }

  .registerBut{
    background-color: #103989;
    color:white;
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 14px;
  }

  .userDropMenu{
    position: absolute;
    user-select: none;
    z-index: 10;
    width: 150px;
    top: 60px;
    right: 0px;
    font-size: 14px;
    padding: 16px 16px 16px 10px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color:#292527;
  }

  .userDropMenuItem{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }


  .userDropMenu i{
    color:#103989;
    margin-right: 5px;
  }

  .userDropMenu h3{
    color: #979797;
    font-weight: bold;
  }

  .logout{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color:#ea1a28;
  }

  .logout i{
    color:#ea1a28;
  }

  .bottomButtons{
    display: flex;
    height: 100%;
    align-items: center;
  }

  .registerBut{
    align-self: center;
  }

  .loginBut{
    margin: 0 20px 0 40px;
    padding: 5px 70px;
    border: 1px solid #DADBDC;
    color: #6B6E74;
  }

  .fakeDiv{
    display: none;
  }

  .backgroundImage{
    transform: translate(-50%);
    left: 50%;
    position: absolute;
    width: 100%;
    top: -190px;
  }

  .notificationCircle{
    background-color: #ea1a28;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-left: 10px;
  }

  .headerButtons{
    display: none;
  }

  .cabinetText{
    font-size: 16px;
    line-height: 21px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #103989;
    font-weight: bold;
  }

  .buyLotBut{
    color: #057E36;
    border-radius: 4px;
    border: 1px solid #057E36;
    padding: 5px 40px;
    margin-right: 25px;
  }

  .sellLotBut p{
    color: #103989;
    border-radius: 4px;
    border: 1px solid #103989;
    padding: 5px 40px;
    margin-right: 25px;
  }


  .plusButton, .bellButton, .menuButton{
    display: none;
    color: #979797;
    font-size: 30px;
    margin-left: 20px;
    cursor: pointer;
  }

  .listItem{
    display: flex;
    align-items: center;
  }

  .sideMenu{
    display: none;
  }

  .fakeDiv{
    display: none;
  }

  @media (max-width: 1450px) {
    .navigation{
      padding: 0 5%;
    }
  }

  @media (max-width: 1270px) {
    .logo img {
      position: static;
      transform: none;
    }

    .backgroundImage{
      transform: translate(-50%);
      left: 50%;
      position: absolute;
      top: -190px;
    }
  }

@media (max-width: 952px){

  .notificationCircleMobile{
    color: #ea1a28;
    position: absolute;
    font-size: 12px;
    left:12px;
    top:7px
  }

  .logo img{
    font-size: 30px;
  }
  .navigation .list li .link{
    font-size: 16px;
  }
}
@media (max-width: 858px){
  .logo img{
    width: 200px;
  }
}

@media (max-width: 700px){
  .navigation{
    width: 100vw;
  }

  .logo img{
    width: 200px;
  }

  .cabinetText, .sellLotBut p, .userBar{
    display: none;
  }

  .plusButton, .menuButton, .bellButton{
    display: block;
  }

  .sellLotBut{
    display: flex;
  }

  .navigation{
    position: fixed;
  }

  .menuInput:checked ~ section{
    display: block;
    height: calc(100vh - 80px);
    width: 100%;
    top: 80px;
    z-index: 1;
    left: 0;
    position: fixed;
    background-color: white;
    transition: all 0.5s ease-out;
    padding-top: 30px;
    padding-left: 30px;
  }
  .fakeDiv{
    height: 80px;
    display: block;
  }
  .active{
    color: #292527;
    font-weight: bold;
  }

  .sideMenu h1{
    color: #292527;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 30px;
  }

  .sideMenu li i{
    color: #889cc4;
    font-size: 20px;
    margin-right: 20px;
  }

  .sideMenu li{
    color: #6B6E74;
    font-size: 16px;
    padding: 20px 40px 20px 10px;
    border-bottom: 1px solid #ededee;
  }

  .sideMenu li a{
    font-weight: bold;
  }

  .sideMenu ul{
    list-style-type: none;
  }

  .logout{
    color: #ea1a28;
    margin-top: 20px;
    font-weight: 700;
    padding-left: 10px;
    cursor: pointer;
  }

  .logout i{
    font-size: 20px;
    margin-right: 20px;
  }

}

  @media (max-width: 400px){
    .logo img{
      width: 150px;
    }
    .plusButton, .menuButton, .bellButton{
      font-size: 20px;
    }
  }
  /*
   .logo img{
    height: 40px;
    padding-left: 20px;
  }

  .navigation{
    flex-direction: row-reverse;
    padding: 0 0;
    position: fixed;
  }

  .checkbtn{
    display: block;
  }
  .list{
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    top: 0px;
    padding-bottom: 40px;
    padding-top: 80px;
    left: -100%;
    text-align: left;
    transition: all .5s;
    z-index: 90;
    box-sizing: border-box;
  }
  .navigation .list li .link{
    padding: 0;
  }
  .navigation .list li{
    display: block;
    line-height: 30px;
  }
  .navigation .list li .link{
    font-size: 20px;
  }
  #check:checked ~ .list {
    left: 0px;
  }

  #check:checked .navigation{
    height: 300px;
  }

  .bottomButtons{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-around;
  }

  .registerBut{
    width: 40%;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    border-radius: 20px;
    align-items:center;
    justify-content: center;
  }

  .userBar{
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
  }

  .userDropMenu{
    position: relative;
    top:0px;
    padding: 0;
    box-shadow: none;
    width: 100%;
  }

  .userDropMenu h3{
    margin-top: 20px;
    font-size: 20px;
  }

  .userDropMenuItem{
    padding: 15px 0 15px;
    font-size: 18px;
    white-space: nowrap;
    border-bottom: 1px solid #dfdfdf;
  }

  .logout{
    padding: 15px 0 15px;
    font-size: 20px;
    white-space: nowrap;
    border-bottom: 1px solid #dfdfdf;
  }
  .alert, .menuButton{
    display: none;
  }

  .registerBut{
    position: absolute;
    bottom: 40px;
    right: 15%;
  }
  .loginBut{
    position: absolute;
    bottom: 40px;
    left: 15%;
    padding-bottom: 5px;
  }


  .sideBarInfo{
    display: flex;
    align-items: center;
  }

  .sideBarUserIcon{
    margin-top: 20px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .sideBarInfo h3{
    margin-top: 20px;
    margin-left: 15px;
    color: #231f20;
  }

  .headerButtons{
    display:flex;
    align-items: center;
    font-size: 25px;
    color:#979797;
  }

  .headerButtons i{
    margin-right: 20px;
  }
  */