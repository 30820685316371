.Footer{
    background-color: #FAFAFA;
    width: 100%;
    height: 360px;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
}

.logo{
    margin-left: 50%;
    width: 200px;
    transform: translate(-50%, 0);
    margin-bottom: 40px;
}

.footerContent{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.footerColumn{
    display: flex;
    flex-direction: column;
}

.footerColumn a{
    text-transform: uppercase;
    font-size: 14px;
    color: #292527;
    margin-bottom: 20px;
    font-weight: 600;
}

.line{
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #ea1a28;
}

.footerTop{
    padding: 40px 10%;
    box-sizing: border-box;
}

.footerBottom{
    text-align: center;
    border-top: 1px solid rgba(73,73,73,0.2);
    padding-top: 20px;
    font-size: 14px;
}

.imagesBlock{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    cursor: pointer;
}

.imagesBlock img{
    border: 1px solid #E0E0E0;
    padding: 10px;
    height: 15px;
    width: 15px;
    border-radius: 6px;
    margin-left: 15px;
    box-sizing: content-box;
}