/*.react-dropdown-select-item-selected{*/
/*    color: #6b6e74 !important;*/
/*}*/

.react-dropdown-select{
    height: 40px;
    border: 1px solid #dadbdc !important;
    width: 100%;
}





.react-dropdown-select:disabled{
    height: 40px;
    border: 1px solid #dadbdc !important;
    width: 100%;
}

.react-dropdown-select:hover{
    border: 1px solid #ccc !important;
}

.select-disabled{
    background-color: #00000008;
    opacity: 100% !important;
}

.select-disabled input:disabled{
    background-color: rgba(0,0,0,0) !important;
}

.react-dropdown-select-item-selected{
    color: #292527 !important;
}

.react-dropdown-select-content span{
    height: 25px;
    overflow: hidden;
    width: 95%;
}