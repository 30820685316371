.ExtendedFilters{
    padding: 30px 10%;
}

.content{
    background-color: white;
    padding: 50px 30px;
    border-radius: 4px;
    padding-right: 15%;
}

.subtitle{
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
}

.inputItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: inherit;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;
    padding-right: 30px;

}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}

.itemRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
}

.checkboxItem{
    width: 70%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.checkboxItem input{
    margin-top: 5px;
    margin-right: 5px;
}

.line{
    height: 1px;
    background-image: url('../../assets/img/dashed-line.png');
    margin: 30px 0;
}

.doubleInputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.short{
    width: calc(50% - 20px);
}

.resetFilter{
    color: #103989;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}