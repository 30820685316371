.content{
    padding: 30px 10%;
}

.container {  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "div1 div1 div2"
    "div3 div4 div5"
    "div6 div7 div5"
    "div8 div8 div8";
}

.container div{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DADBDC;
    color: #292527;
    background-color: white;
}

.div1 {
    grid-area: div1;
    background-color: #E1EAFB !important;
    font-weight: 600;
}

.div2 {
    grid-area: div2;
    background-color: #E1EAFB !important;
    font-weight: 600;
}

.div3 {
    grid-area: div3;
    background-color: #EEFEF5 !important;
    font-style: italic;
}

.div4 {
    grid-area: div4;
    background-color: #EEFEF5 !important;
    font-style: italic;
}

.div5 {
    grid-area: div5;
    margin-bottom: 10px;
}

.div6 {
    grid-area: div6;
    margin-bottom: 10px;
}

.div7 {
    grid-area: div7;
    margin-bottom: 10px;
}

.div8 {
    grid-area: div8;
    display: block !important;
}

.annotation{
    font-style: italic;
    margin-bottom: 25px;
}

.infoBlock{
    background-color: #FFF4DF !important;
    padding: 20px;
    border: none !important;
    border-left: 2px solid #FFAA00 !important;
}

.title{
    text-align: center;
    text-transform: uppercase;
}

.content h3{
    text-align: center;
    margin-bottom: 30px;
}

