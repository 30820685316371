.content{
    padding: 50px 10%;
}
.newsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.newsItem img{
    width: 100%;
    height: 15vw;
    object-fit: contain;
    border-radius: 4px;
    margin-bottom: 20px;
}

.newsItem h5{
    margin: 10px 0;
    font-size: 16px;
}

.date{
    color: #103989;
    border: 1px solid #103989;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline;
}

.description{
    color: #6B6E74;
    font-style: italic;
}