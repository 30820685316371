
.NestedSelect{
    position: relative;
}

.NestedSelect input{
    width: 100%;
}

.dropdown{
    position: absolute;
    /*width: calc(70% - 60px);*/
    width: 100%;
    background-color: white;
    z-index: 2;
    border: 1px solid #DADBDC;
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

.categoryItem{
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.categoryItem img{
    margin-right: 5px;
}

.categoryItem:hover{
    background: rgba(240, 241, 241, 0.6);
}

.categoryItem p{
    display: inline;
    color:rgb(107, 110, 116);
}