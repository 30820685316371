.SignComponent{
    padding: 30px 40px;
}

.keyTypeBlock, .keyMediaBlock{
    display: none;
}

.keySelectBlock, .signBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fileSelectButton{
    color: #103989;
    width: 70%;
    border-radius: 4px;
    border: 1px solid #103989;
    height: 40px;
    background-color: white;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.signButton{
    width: 70%;
    height: 40px;
    background-color: #103989;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hiddenInput{
    height: 0px;
    padding: 0;
    display: none;
}

.selectedFileName{
    color: #103989;
    align-self: flex-start;
    margin-left: 15%;
    word-break: break-all;
    max-width: 70%;
}

.SignComponent label{
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
}

.SignComponent input{
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 8px 12px;
    font-size: 16px;
    width: 70%;
}

.error{
    font-weight: 500;
    color: #ea1a28;
}
