.itemRow{
    display: grid;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    grid-gap: 40px;
}

.inputItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.itemRowTriple{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: white;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;
    padding-right: 30px;

}

.inputItem input:disabled{
    background-color: #00000008;
}

.inputItem textarea:disabled{
    background-color: #00000008;
}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}

.subtitle{
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 30px;
    color: #292527;
    opacity: 0.5;
}

.title{
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #292527;
}


.removeAccountBtn{
    align-self: center;
    height: 40px;
    margin-top: 20px;
    display: flex;
    border: 1px solid #ea1a28;
    border-radius: 4px;
    color: #ea1a28;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.removeAccountBtn p{
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
}

.removeAccountBtnPlus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 13px;
    font-size: 30px;
    color: #ea1a28;
    border-right: 1px solid #ea1a28;
}

.blueBtn{
    border: 1px solid #103989;
    color: #103989;
}

.blueBtn .removeAccountBtnPlus {
    border-right: 1px solid #103989;
    color: #103989;
}

.redBtn{
    border: 1px solid #ea1a28;
    color: #ea1a28;
}

.redBtn .removeAccountBtnPlus {
    border-right: 1px solid #ea1a28;
    color: #ea1a28;
}

.checkboxItem{
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.checkboxItem input{
    margin-top: 5px;
    margin-right: 5px;
}

.checkboxItemLabel{
    margin: 0 !important;
}

.line{
    height: 1px;
    background-image: url('../../assets/img/dashed-line.png');
    margin: 30px 0;
}

.button{
    height: 40px;
    width: 100%;
    border-radius: 4px;
    padding: 0 10px;
    background-color: #103989;
    color: white;
    font-weight: 600;
}

.short{
    width: calc(50% - 20px);
}

.error{
    font-weight: 600;
    margin-top: 20px;
    color: #ea1a28;
}

.outlinedBtn{
    border: 1px solid red;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btnDisabled{
    background-color: #00000008;
}