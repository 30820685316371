
.questionItemContent{
    display: flex;
    justify-content: space-between;
}

.questionItemRight{
    display: flex;

}

.counter{
    background-color: #ABB3D0;
    border-radius: 50%;
    padding: 0 7px;
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.questionLink{
    color: #103989;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
}

.questionDate{
    font-size: 14px;
    color: #6B6E74;
    margin-bottom: 10px;
}

.questionTitle{
    color: #6B6E74;
    font-weight: 500;
}

.questionTitle span{
    color: #292527;
}

.questionItem{
    border-bottom: 1px solid #ECEDED;
    padding: 15px 0;
    padding-right: 20px;
}

.lotQuestionsBox{
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-color: #f2f2f2 white;
    scrollbar-width: thin;

}

.lotQuestionsBox::-webkit-scrollbar-button{
    height: 10px;
}

.lotQuestionsBox::-webkit-scrollbar{
    width: 7px;
    background-color: white;
}

.lotQuestionsBox::-webkit-scrollbar-thumb{
    width: 12px;
    background-color: #103989;
    border-radius: 15px;
    border: 1px solid #f2f2f2;
    border-right: 2px solid white;
}
.questionButton{
    height: 40px;
    background-color: #103989;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    width: 30%;
    align-self: flex-end;
    margin-top: 15px;
}

.Questions{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
}


.inputItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: inherit;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;

}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}

.messageLetters{
    font-size: 14px;
    color: #6B6E74;
    margin: 10px 0 30px;
}

.Chat{
    padding-top: 10px;
}

.chatMessagesBox{
    height: 500px;
}

.chatHeader p{
    color: #6B6E74;
    font-size: 14px;
}

.chatHeader{
    border-bottom: 1px solid #ECEDED;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}

.chatTitle{
    color: #292527;
    font-weight: 500;
}

.chatControls button{
    background-color: #103989;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.chatControls{
    display: flex;
    padding: 10px 0;
}

.chatControls input{
    flex-grow: 1;
    box-sizing: border-box;
    border: 1px solid #DADBDC;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 10px;
    caret-color: #103989;
}

.dateBox{
    display: flex;
}

.dateBox p{
    background-color: #F2F2F2;
    padding: 5px 15px;
    border-radius: 4px;
    color: #6B6E74;
    display: inline;
    position: relative;
}

.dateBox div{
    flex-grow: 1;
    border-bottom:1px solid #ECEDED;
    transform: translate(0, -50%);
}


.chatMessagesBox{
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    overflow-y: scroll;
}

.rightMessage{
    align-self: flex-end;
}

.rightMessage .messageContent{
    background-color: #F4F4F4;
    border-radius: 4px 4px 4px 0px;
}

.leftMessage .messageContent{
    background-color: #EBF2FD;
    border-radius: 4px 4px 0px 4px;
}

.messageContent{
    padding: 15px;
    border-radius: 4px;
    padding-bottom: 25px;
}

.message{
    max-width: 60%;
    position: relative;
}

.message h5{
    color: #103989;
    margin-bottom: 10px;
}

.leftMessage .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 35px 0 0;
    border-color: #EBF2FD transparent transparent transparent;
}

.rightMessage .triangle {
    float: right;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 35px 35px 0;
    border-color: transparent #F4F4F4 transparent transparent;
}


.chatMessagesBox::-webkit-scrollbar-button{
    height: 10px;
}

.chatMessagesBox::-webkit-scrollbar{
    width: 7px;
    background-color: white;
}

.chatMessagesBox::-webkit-scrollbar-thumb{
    width: 12px;
    background-color: #DADBDC;
    border-radius: 15px;
    border: 1px solid #f2f2f2;
    border-right: 2px solid white;
}

.backArrow{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.backArrow i{
    margin-right: 10px;
    color: #6B6E74;
}

.questionTheme{
    display: flex;
    justify-content: space-between;
}

.questionTheme p{
    color: #6B6E74;
    cursor: pointer;
}

.questionTheme i{
    margin-right: 10px;
    color: #6B6E74;
    cursor: pointer;
}

.error{
    color: #ea1a28;
}