.Cabinet{
    padding: 30px 10%;
}

.block{
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    margin-bottom: 30px;
}

.collapseButton{
    width: 20px;
    height: 20px;
    position: absolute;
    top:30px;
    right: 30px;
    background-color: white;
    border: 1px solid rgb(218, 219, 220);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.contractBlock a{
    color: #103989;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}

.alertBlock{
    background: rgba(253, 126, 125, 0.25);
    padding: 6px 7%;
    text-align: center;

}

.alertBlock p{
    position: relative;
}


.alertBlock img{
    z-index: 1;
    left: -30px;
    top: calc((100% / 2) - 8px);
    position: absolute;
}

.mainBlock{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.mainBlock img{
    width: 140px;
    height: 140px;
    object-fit: contain;
    border-radius: 50%;
}

.photoChangeIcon p{
    margin-left: 14px;
    color: #103989;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.photoButtons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.successModal{
    padding: 30px;
    text-align: center;
}

.successModal button{
    background-color: #103989;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    margin-top: 20px;
}

.success{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.colonItem{
    width: 100%;
}

.successContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: white;
    padding: 30px;
}

.successContent p{
    margin-bottom: 20px;
}

.successContent h2{
    margin-bottom: 20px;
}

.successContent div div{
    background-color: #f2f7fb;
}

.successContent label{
    font-size: 1em !important;
    font-weight: normal !important;
    line-height: normal !important;
    margin-top: 75px;
    height: 10px;
    margin-bottom: 20px;
    color: #6b6e74 !important;
    padding: 10px 18px 17px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
}

.error{
    margin-top: 10px;
    color: #ea1a28;
}

.acceptBtn{
    width: 126px;
    height: 40px;
    padding: 9px 18.8px 10px 18px;
    border-radius: 20px;
    background-color: #103989;
    color: white;
    font-weight: bold;
}

.closeBtn{
    width: 106px;
    height: 40px;
    margin: 15px 20px 0 2px;
    padding: 9px 16px 10px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
}


.dropBox{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: dashed 1px #556f99;
    background-color: #f2f7fb;
    margin-top: 10px;
    padding: 0 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropBox h3{
    color: rgba(38, 39, 41, 0.56);
    text-align: center;
}

.dropBox button{
    height: 40px;
    width: 30%;
    color: #6b6e74;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
    margin: auto;
}

.dropBox input{
    display: none;
}