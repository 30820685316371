.content{
    padding: 30px 10%;
}

.title{
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
}


.block{
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    margin-bottom: 30px;
}

.collapseButton{
    width: 20px;
    height: 20px;
    position: absolute;
    top:30px;
    right: 30px;
    background-color: white;
    border: 1px solid rgb(218, 219, 220);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}