body{
    background-color: #f2f2f2;
}
.topBar{
    background-color: white;
    padding-top:36px;
}
.title{
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
}
.list{
    padding: 0 10%;
    display: flex;
    max-width: 100%;

}

.active div{
    background-color: #f2f2f2;
    border-radius: 8px 8px 0 0;
    color:#103989;
}

.active .count{
    background-color:#abb3d0;
    border-radius: 8px;
    color:white;
}
.content{
    padding: 0 10%;
}
.listItem{
    display: flex;
    padding: 12px 36px;
    align-items: center;
    color: #6b6e74;
    white-space: nowrap;
}
.count{
    margin-left: 5px;
    font-size: 12px;
    padding: 4px;
    background-color:#abb3d0;
    color:white;
    border-radius: 11px;
}

.filter{
    padding:0 10%;
}

.filterBottom{
    display: flex;
    justify-content: space-between;
    color:#6b6e74;
    padding-bottom: 20px;
}
.filterBottomLeft{
    display: flex;
}
.resetBtn{
    border: 1px solid #dadbdc;
    font-size: 14px;
    padding: 7px 16px;
    border-radius: 20px;
}
.resetBtn:focus{
    border: 1px solid #dadbdc;
}
.filterBox{
    font-size: 16px;
    background-color: white;
    color: #6b6e74;
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: solid 1px #dadbdc;
    flex-basis: 15%;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
}

.rotatedSvg{
    transform: rotate(180deg);
}

.svg{
    width: 16px;
    color: black;
}


.filterBox p{
    margin-right: 5px;
}
.filterInput{
    position: relative;
    flex-basis: 40%;
}
.filterInput input{
    box-sizing: border-box;
    font-size: 16px;
    background-color: white;
    color: #6b6e74;
    display: flex;
    align-items: center;
    padding: 7px 10px 7px 35px;
    width: 100%;
    height: 100%;
    border: solid 1px #dadbdc;
    border-radius: 4px;
    padding-left: 35px !important;
}


.filterInput i{
    position: absolute;
    z-index: 1;
    bottom: 9px;
    left: 5px;
    font-size: 24px;
    color:#6b6e74;

}

.topSearchBar{
    width: 100%;
    height: 310px;
    position: relative;
}

.backgroundImage{
    position: absolute;
    width: 100%;
    top: -210px;
    object-fit: cover;

}

.topFiltersBoxBackground{
    width: 53%;
    height: 73%;
    opacity: 0.6;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.topFiltersBox{
    box-sizing: border-box;
    padding: 25px 65px;
    width: 53%;
    height: 73%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filterTypes{
    display: flex;
    margin-top: 20px;
    gap:5px;
}

.filterTypes p{
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    flex-grow: 1;
    padding: 6px 16px 8px 16px;
    border: solid 1px #dadbdc;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 4px;
}

.filterCategories{
    display: flex;
}

.filterCategories p{
    text-align: center;
    font-size: 16px;
    flex-grow: 1;
    padding: 4px 16px 6px 16px;
    border: solid 1px #dadbdc;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.filterTypeAcive{
    background-color: #dadbdc;
    color: #292527;
}

.filterInputs {
    display: grid;
    margin-bottom: 15px;
    height: 40px;
    grid-gap: 5px;
    gap: 5px;
    grid-template-columns: 4fr 3fr 1.5fr 1.5fr;
}

.sliderFilter input{
    font-size: 16px;
    width: 100px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
}

.sliderInputs{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.sliderLine {
    background-color:#6b6e74;
    opacity: 60%;
    height: 2px;
    margin: 0 5px;
    width: 15px;
}

.filterBox{
    position: relative;
}

.filterBoxContent{
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    width: 100%;
}

.filterDropdown{
    z-index: 10;
    padding: 12px 15px 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    background-color: white;
    top: 42px;
    left: -50%;
}

.filterSearchButton{
    background-color: #057e36;
    color: white;
    height: 40px;
    flex-basis: 15%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
}

.mapFilter p{
    display: inline;
    margin-left: 10px;
    cursor: pointer;
}

.filterCategories{
    flex-wrap: wrap;
    margin-right: -5px;
}

.backgroundImage{
    position: absolute;
    width: 100%;
    object-fit: cover;
    top: 0;
    clip-path: inset(0 0 calc(100% - 310px) 0);
}
.filerBottomBar{
    color: white;
    display: flex;
    justify-content: space-between;
}

.extendedSearch{
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
}

.categoryFilter input{
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    background-color: transparent;
    border: solid 1px #dadbdc;
    color: white;
    font-weight: 700;
    padding:0 15px;
}

.categoryFilter input::placeholder {
    color: white;
}

.categoryFilter{
    margin-bottom: 5px;
    position: relative;
}

.statusFilter{
    flex-basis: 30%;
    border-radius: 4px;
}

@media (max-width: 1200px){
    .filterInputs{
        grid-template-columns: 2fr 1fr 1fr;
    }

    .filerBottomBar{
        margin-top: 55px;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .filterSearchButton{
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }

    .topFiltersBoxBackground{
        height: 100%;
    }

    .topFiltersBox{
        top:40%;
    }
}
@media (max-width: 1100px){
    .filterInputs{
        grid-template-columns: 1fr 1fr;
    }

    .filterSearchButton{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
    }

    .filterInput{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
    }

    .filerBottomBar{
        margin-top: 100px;
    }

    .topSearchBar{
        height: 400px;
    }

    .backgroundImage{
        clip-path: inset(0 0 calc(100% - 400px) 0);
    }

    .topFiltersBoxBackground{
        height: 90%;
    }

    .topFiltersBox{
        top:43%
    }
}

@media (max-width: 1100px){
    .topFiltersBox{
        width: 70%;
    }

    .topFiltersBoxBackground{
        width: 70%;
    }

    .backgroundImage{
        clip-path: none;
        height: 100%;
    }
}

@media (max-width: 650px){
    .topFiltersBox{
        width: 100%;
        padding: 25px 45px;
    }

    .topFiltersBoxBackground{
        width: 100%;
    }
}
